import React, { Component } from 'react';
import Peer from 'simple-peer'
// import PeerConnection from 'rtcpeerconnection'

const dataChannelOptions = {
   ordered: false, // do not guarantee order
   maxPacketLifeTime: 3000, // in milliseconds
};

class App extends Component {

   constructor() {
      super()
      this.state = {
         peer1: null,
         peer2: null
      }
   }

   componentDidMount() {

      let is_client = window.location.hash === '#client'
      // const peerConnection = new RTCPeerConnection();
      //
      //
      // const dataChannel = peerConnection.createDataChannel("label", dataChannelOptions);
      //
      //
      // console.log(peerConnection)
      // console.log(dataChannel)
      //
      // dataChannel.onerror = (error) => {
      //    console.log("Data Channel Error:", error);
      // };
      //
      // dataChannel.onmessage = (event) => {
      //    console.log("Got Data Channel Message:", event.data);
      // };
      //
      // dataChannel.onopen = () => {
      //    console.log('open')
      //    dataChannel.send("Hello World!");
      // };
      //
      // dataChannel.onclose = () => {
      //    console.log("The Data Channel is Closed");
      // };

      // var peer1 = new Peer({ initiator: is_client, trickle: false,})
      var peer = new Peer({ initiator: is_client, trickle: false,
         // config: {
         //    iceServers: [
         //       {
         //          "urls": "stun:numb.viagenie.ca",
         //       //    "username": "owen@wunderfauks.com",
         //       //    "credential": "owen@wunderfauks123"
         //       },
         //       {
         //          urls: [ "turn:numb.viagenie.ca" ],
         //          username: "owen@wunderfauks.com",
         //          credential: "owen@wunderfauks123",
         //       }
         //    ]
         // }
         // config: {
         //    "rtcpMuxPolicy":"require",
         //    "bundlePolicy":"max-bundle",
         //    "iceServers":[
         //       {
         //          "urls":["stun:stun.l.google.com:19302"]
         //       },
         //       {
         //          "urls":["turn:numb.viagenie.ca"],
         //          "username":"owen@wunderfauks.com",
         //          "credential":"owen@wunderfauks123",
         //          "maxRateKbps":"8000"
         //       }
         //    ],
         // }
      })
      console.log(peer)
      // console.log(peer1)
      peer.on('signal', (data) => {
         console.log(JSON.stringify(data))
         document.getElementById('yourId').value = JSON.stringify(data)
      })


      peer.on('connect', () => {
         document.getElementById('connected').textContent = 'Connected'
      })

      peer.on('data', (data) => {
         // console.log(data)
         document.getElementById('output').textContent += data + '\n'
         // console.log(Math.floor(Date.now() / 1000))
         // console.log(new Date().getTime())
         let time = new Date().getTime()
         document.getElementById('time').textContent += time + '\n'
      } )

      this.setState({ peer })








      //
      // var peer2 = new Peer({ initiator: false, trickle: false, iceTransportPolicy: 'relay', })
      //
      // console.log(peer1._pc.localDescription)
      // console.log(peer2)
      //
      //
      // peer1.on('signal', function (data) {
      //    console.log('p1 on signal')
      //    console.log(data)
      //    peer2.signal(data)
      // })
      //
      // peer2.on('signal', function (data) {
      //    console.log('p2 on signal')
      //    console.log(data)
      //    peer1.signal(data)
      // })
      //
      // peer1.on('connect', function () {
      //    // peer1.send('hey peer2, how is it going?')
      //    console.log('--------------------P1 connected------------------')
      //    console.log(peer1._pc.localDescription)
      //    console.log(peer1._pc.remoteDescription)
      // })
      // peer2.on('connect', () => {
      //    console.log('--------------------P2 Connected-------------------')
      //    console.log(peer1._pc.localDescription)
      //    console.log(peer1._pc.remoteDescription)
      // })
      //
      // peer1.on('end', function() {
      //    console.log('Connection Ended')
      // })
      //
      //
      // peer2.on('data', function (data) {
      //    console.log('Got a message from peer1: ' + data)
      //    document.querySelector('#output').textContent = data
      // })
      // this.setState({ peer1, peer2 })
      //

   }

   onConnect = () => {
      const {peer} = this.state
      let otherId = JSON.parse( document.getElementById('otherId').value )
      peer.signal(otherId)

   }
   onSend = () => {
      const {peer} = this.state
      let message = document.getElementById('message').value
      peer.send(message)
      // console.log(Math.floor(Date.now() / 1000))
      console.log(new Date().getTime())
      let time = new Date().getTime()
      document.getElementById('time').textContent += time + '\n'
   }

   onP2send = () => {
      // const {peer1, peer2} = this.state
      // peer2.on('signal', function (data) {
      // peer1.signal(data)
      // })
      // peer1.send('hey')
      const {peer} = this.state

   }

   render() {
      return(
         <div>
            <h1>P2P</h1>

            <textarea id="yourId" cols="30" rows="10" >
            </textarea>
            <br />
            <textarea name="" id="otherId" cols="30" rows="10"></textarea>
            <br />
            <button onClick={this.onConnect}>
               Connect
            </button>
            {/* <button onClick={this.onP2send}>
            P2 Send
         </button> */}
         <br/>
         <p id="connected"></p>
         <br/>



         <textarea name="" id="message" cols="30" rows="10"></textarea>
         <br/>
         <button onClick={this.onSend}>
            Send
         </button>

         <p id="output"></p>
         <p id="time"></p>
      </div>
   )
}

}
export default App;
